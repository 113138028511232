import React from 'react';
import FinanceHeader from '../../../Components/Body/FinanceComponent/FinanceHeader';
import Navbar2 from '../../../Components/Header/Navbar2';
import FinanceSubsection from '../../../Components/Body/FinanceComponent/Subsection/FinanceSubsection';
import Footer2 from '../../../Components/Footer/Footer2';
import FinanceSubsection2 from '../../../Components/Body/FinanceComponent/Financesection2/FinanceSubsection2';

const Finance = () => {




  return (

      <div>
<Navbar2/>
<FinanceHeader/>
<FinanceSubsection/>
<FinanceSubsection2/>
<Footer2/>

      
    </div>
  );
}

export default Finance;
